// @mui
import { Theme, SxProps } from '@mui/material/styles';
import Button from '@mui/material/Button';
// routes
import { RouterLink } from 'src/routes/components';
// config
import { PATH_AFTER_LOGIN } from 'src/config-global';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from 'src/authConfig';

// ----------------------------------------------------------------------

type Props = {
  sx?: SxProps<Theme>;
};

export default function LoginButton({ sx }: Props) {
  const { instance } = useMsal();

  async function handleLogin() {
    try {
      instance.loginPopup(loginRequest).catch((e) => {
        console.error(e);
      });
    } catch (ee) {
      console.error('ee', ee);
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    handleLogin();
  };

  return (
    <Button
      component={RouterLink}
      href={PATH_AFTER_LOGIN}
      variant="outlined"
      sx={{ mr: 1, ...sx }}
      onClick={handleClick}
    >
      Login
    </Button>
  );
}
