import { Helmet } from 'react-helmet-async';
// @mui
import { Box } from '@mui/material';

import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { SignIn } from 'src/components/AzureAD/SignIn';

// section
import { ProfileView } from 'src/sections/profile';

// ----------------------------------------------------------------------

export default function Profile() {
  return (
    <>
      <AuthenticatedTemplate>
        <Box mb={3}>
          <Helmet>
            <title> Profile</title>
          </Helmet>

          <ProfileView />
        </Box>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <SignIn />
      </UnauthenticatedTemplate>
    </>
  );
}
