import { useContext, useEffect, useState } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
// @mui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
import { DataLagoonContext } from 'src/DataLagoonContext';

// theme
import { bgBlur } from 'src/theme/css';
// components
import Logo from 'src/components/logo';
//
import { HEADER } from '../config-layout';
import {
  navConfigUnAuthed,
  navConfigAuthed,
  navConfigAuthedAdminApprover,
} from './config-navigation';
import NavMobile from './nav/mobile';
import NavDesktop from './nav/desktop';
//
import { SettingsButton, HeaderShadow, LoginButton, AccountPopover } from '../_common';
//

// ----------------------------------------------------------------------

export default function Header() {
  const theme = useTheme();

  const mdUp = useResponsive('up', 'md');

  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);

  const isAuthenticated = useIsAuthenticated();

  const { state } = useContext(DataLagoonContext);

  const [userNav, setUserNav] = useState<any>(navConfigUnAuthed);

  // let userNav = navConfigUnAuthed;

  useEffect(() => {
    if (isAuthenticated && state?.me !== undefined && state?.me !== null && state?.me !== -1) {
      if (
        state?.me?.dataSetApprovals.length > 0 ||
        state?.me?.rolesAssignments.filter((role: any) => role.role === 'Admin').length > 0
      ) {
        setUserNav(navConfigAuthedAdminApprover);
      } else {
        setUserNav(navConfigAuthed);
      }
    }
  }, [state, userNav, isAuthenticated]);

  return (
    <AppBar>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(offsetTop && {
            ...bgBlur({
              color: theme.palette.background.default,
            }),
            height: {
              md: HEADER.H_DESKTOP_OFFSET,
            },
          }),
        }}
      >
        <Container sx={{ height: 1, display: 'flex', alignItems: 'center' }}>
          <Box>
            <Logo mx={3} />
          </Box>
          {/* 
          <Link
            href={paths.changelog}
            target="_blank"
            rel="noopener"
            underline="none"
            sx={{ ml: 1 }}
          /> */}

          <Box sx={{ flexGrow: 1 }} />

          {mdUp && <NavDesktop offsetTop={offsetTop} data={userNav} />}

          <Stack alignItems="center" direction={{ xs: 'row', md: 'row-reverse' }}>
            <SettingsButton
              sx={{
                ml: { xs: 1, md: 0 },
                mr: { md: 2 },
              }}
            />

            {!mdUp && <NavMobile offsetTop={offsetTop} data={userNav} />}
          </Stack>
          {isAuthenticated ? <AccountPopover /> : <LoginButton />}
        </Container>
      </Toolbar>

      {offsetTop && <HeaderShadow />}
    </AppBar>
  );
}
