import { Grid } from '@mui/material';
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';
import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
// import { SplashScreen } from 'src/components/loading-screen';
// layouts
// import CompactLayout from 'src/layouts/compact';
import MainLayout from 'src/layouts/main';
import Profile from 'src/pages/Profile';

// ----------------------------------------------------------------------

const Page404 = lazy(() => import('src/pages/404'));
const DataSets = lazy(() => import('src/pages/DataSets'));
export const HomePage = lazy(() => import('src/pages/HomePage'));
export const Support = lazy(() => import('src/pages/Support'));
export const Users = lazy(() => import('src/pages/Users'));
export const LogHistory = lazy(() => import('src/pages/LogHistory'));
// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    element: (
      <MainLayout>
        <Suspense
          fallback={
            <Container maxWidth="lg">
              <Grid spacing={2} mt={13} p={10}>
                <LinearProgress />
              </Grid>
            </Container>
          }
        >
          <Outlet />
        </Suspense>
      </MainLayout>
    ),
    children: [
      { path: '404', element: <Page404 /> },
      { path: 'home', element: <HomePage /> },
      { path: 'support', element: <Support /> },
      { path: 'contact-us', element: <Support /> },
      { path: 'about-us', element: <Support /> },
      { path: 'datasets', element: <DataSets /> },
      { path: 'users', element: <Users /> },
      { path: 'loghistory', element: <LogHistory /> },
      { path: 'profile', element: <Profile /> },
    ],
  },
];
