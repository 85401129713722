import { useContext, useEffect, useState } from 'react';
import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { EventMessage, EventType, InteractionRequiredAuthError } from '@azure/msal-browser';
// DataLagoon
import { DataLagoonContext } from './DataLagoonContext';
import { protectedResources } from './authConfig';

export default function Me() {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const { state, update } = useContext(DataLagoonContext);
  const [loggedIn, setLoggedIn] = useState(false);
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    // This will be run on component mount
    const callbackId = instance.addEventCallback((message: EventMessage) => {
      // This will be run every time an event is emitted after registering this callback
      if (message.eventType === EventType.LOGIN_SUCCESS) {
        // Do something with the result
        setLoggedIn(true);
      }
    });

    if (isAuthenticated) {
      setLoggedIn(true);
    }

    return () => {
      // This will be run on component unmount
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const method = 'GET';
    const apiUrl = `${protectedResources.dataLagoonApi.endpoint}/me`;
    if (
      (account && state.me === null && loggedIn === true) ||
      (account && state.me === -1 && isAuthenticated === true)
    ) {
      instance
        .acquireTokenSilent({
          scopes: protectedResources.dataLagoonApi.scopes,
          account,
        })
        .then(async (response) => {
          try {
            const meResponse = await fetch(apiUrl, {
              method,
              headers: {
                Authorization: `Bearer ${response.accessToken}`,
                'Content-Type': 'application/json',
              },
            });

            if (meResponse.status === 200) {
              const json = await meResponse.json();

              update({ me: json });
              // enqueueSnackbar('Saved', { variant: 'success' });
            } else if (meResponse.status === 403) {
              // enqueueSnackbar('Unauthorised', { variant: 'error' });
            } else {
              // enqueueSnackbar('Error. Please try again', { variant: 'error' });
            }
          } catch (error) {
            console.log(error);
            throw new InteractionRequiredAuthError(error);
          }
        })
        .catch((error) => {
          console.log(error);
          // in case if silent token acquisition fails, fallback to an interactive method
          if (error instanceof InteractionRequiredAuthError) {
            if (account) {
              instance
                .acquireTokenPopup({
                  scopes: protectedResources.dataLagoonApi.scopes,
                  //
                })
                .then(async (response) => {
                  try {
                    const meResponse = await fetch(apiUrl, {
                      method,
                      headers: {
                        Authorization: `Bearer ${response.accessToken}`,
                        'Content-Type': 'application/json',
                      },
                    });

                    if (meResponse.status === 200) {
                      const json = await meResponse.json();

                      update({ me: json });
                      // enqueueSnackbar('Saved', { variant: 'success' });
                    } else if (meResponse.status === 403) {
                      // enqueueSnackbar('Unauthorised', { variant: 'error' });
                    } else {
                      // enqueueSnackbar('Error. Please try again', { variant: 'error' });
                    }
                  } catch (error2) {
                    console.log(error2);
                  }
                })
                .catch((error1) => console.log(error1));
            }
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, loggedIn, state, isAuthenticated]);

  return null;
}
