import { useState, useCallback, useContext } from 'react';

// @mui
import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';

import { useAccount, useMsal } from '@azure/msal-react';

// components
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
//
// import ProfileHome from './profile-home';
import { DataLagoonContext } from 'src/DataLagoonContext';
import { Button, CardHeader, Grid, Stack } from '@mui/material';
import ProfileCover from './profile-cover';

// ----------------------------------------------------------------------

const TABS = [
  {
    value: 'profile',
    label: 'Profile',
    icon: <Iconify icon="solar:user-id-bold" width={24} />,
  },
];

// ----------------------------------------------------------------------

export default function ProfileView() {
  const settings = useSettingsContext();

  const { state } = useContext(DataLagoonContext);

  const { user } = useMockedUser();

  const [currentTab, setCurrentTab] = useState('profile');

  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  }, []);

  // const { randomBytes, createHash } = require('node:crypto');

  const renderAbout = (
    <Card>
      <CardHeader title="About" />

      <Stack spacing={2} sx={{ p: 3 }}>
        <Stack direction="row" sx={{ typography: 'body2' }}>
          <Iconify icon="fluent:mail-24-filled" width={24} sx={{ mr: 2 }} />
          {state?.me?.emailAddress}
        </Stack>
        {state?.me?.rolesAssignments?.map((roleAssignment: any) => (
          <Stack key={roleAssignment.id} direction="row" sx={{ typography: 'body2' }}>
            <Iconify icon="fluent:key-32-filled" width={24} sx={{ mr: 2 }} />
            {roleAssignment.role}
          </Stack>
        ))}
      </Stack>
    </Card>
  );

  const renderPasswordReset = (
    <Card>
      <Stack spacing={2} sx={{ p: 3 }}>
        <Button
          variant="contained"
          href={`https://lastingercenter.b2clogin.com/lastingercenter.onmicrosoft.com/oauth2/v2.0/authorize?p=${process.env.REACT_APP_DATALAGOON_B2C_PASSWORD_RESET_POLICY}&client_id=${process.env.REACT_APP_DATALAGOON_UI_CLIENT_ID}&nonce=defaultNonce&redirect_uri=${process.env.REACT_APP_DATALAGOON_B2C_PASSWORD_RESET_POLICY_REDIRECT}&scope=openid&response_type=code&code_challenge=3rqOkGK92Qs1DxX1NGGpccwFoxD3mOqTKbooZug6ITs`}
        >
          Reset Password
        </Button>
      </Stack>
    </Card>
  );

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <Card
        sx={{
          mb: 3,
          height: 290,
        }}
      >
        <ProfileCover
          //   role={_userAbout.role}
          name={`${state?.me?.firstName} ${state?.me?.surname}`}
          avatarUrl={user?.photoURL}
          coverUrl="/assets/background/overlay_6.jpeg"
        />

        <Tabs
          value={currentTab}
          onChange={handleChangeTab}
          sx={{
            width: 1,
            bottom: 0,
            zIndex: 9,
            position: 'absolute',
            bgcolor: 'background.paper',
            [`& .${tabsClasses.flexContainer}`]: {
              pr: { md: 3 },
              justifyContent: {
                sm: 'center',
                md: 'flex-end',
              },
            },
          }}
        >
          {TABS.map((tab) => (
            <Tab key={tab.value} value={tab.value} icon={tab.icon} label={tab.label} />
          ))}
        </Tabs>
      </Card>

      {currentTab === 'profile' && (
        <Grid container gap={2}>
          <Grid item xs={12} md={5}>
            <Stack spacing={2}>
              {renderAbout}
              {account?.idTokenClaims?.authenticationSource === 'localAccountAuthentication'
                ? renderPasswordReset
                : null}
            </Stack>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}
