// @mui
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// routes
import { usePathname } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';

// components
import Logo from 'src/components/logo';
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'Center',
    children: [
      { name: 'About', href: 'https://lastinger.center.ufl.edu/about/' },
      { name: 'News', href: 'https://lastinger.center.ufl.edu/about/news/' },
      { name: 'Make a Gift', href: 'https://lastinger.center.ufl.edu/donate/' },
      {
        name: 'Job Requisitions',
        href: 'https://explore.jobs.ufl.edu/en-us/search/?search-keyword=18300000&job-mail-subscribe-privacy=agree',
      },
    ],
  },
  {
    headline: 'Work',
    children: [
      { name: 'Early Learning', href: 'https://lastinger.center.ufl.edu/work/early-learning' },
      { name: 'Literacy', href: 'https://lastinger.center.ufl.edu/work/literacy' },
      { name: 'Mathematics', href: 'https://lastinger.center.ufl.edu/work/mathematics/' },
      { name: 'Leadership', href: 'https://lastinger.center.ufl.edu/work/leadership/' },
    ],
  },
  {
    headline: 'Contact',
    children: [
      { name: 'Contact', href: 'https://lastinger.center.ufl.edu/contact/' },
      {
        name: 'Online Learning Portal',
        href: 'https://lastinger.center.ufl.edu/online-learning-portal/',
      },
      {
        name: 'New Worlds Reading Initiative',
        href: 'https://lastinger.center.ufl.edu/new-worlds/',
      },
    ],
  },
];

const _socials = [
  {
    value: 'facebook',
    name: 'FaceBook',
    icon: 'eva:facebook-fill',
    color: '#1877F2',
    path: 'https://www.facebook.com/LastingerCenter/',
  },
  {
    value: 'twitter',
    name: 'Twitter',
    icon: 'eva:twitter-fill',
    color: '#00AAEC',
    path: 'https://twitter.com/LastingerCenter',
  },
  {
    value: 'instagram',
    name: 'Instagram',
    icon: 'ant-design:instagram-filled',
    color: '#E02D69',
    path: 'https://www.instagram.com/lastingercenter',
  },
  {
    value: 'linkedin',
    name: 'Linkedin',
    icon: 'eva:linkedin-fill',
    color: '#007EBB',
    path: 'https://www.linkedin.com/company/lastingercenter/',
  },
];

const StyledRight = styled('div')(({ theme }) => ({
  padding: theme.spacing(10, 0),
  [theme.breakpoints.up('lg')]: {
    height: 320,
    padding: 0,
  },
  [theme.breakpoints.up('md')]: {
    borderRadius: '50px 0 0 0',
  },
  backgroundColor: '#021B79',
}));

const StyledLeft = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 0),
  [theme.breakpoints.up('lg')]: {
    height: 320,
    padding: 0,
  },
  [theme.breakpoints.up('md')]: {
    paddingTop: 53,
    // backgroundColor: '#ffffff',
    height: '100%',
  },
}));

// ----------------------------------------------------------------------

export default function Footer() {
  const pathname = usePathname();

  const isHome = pathname === '/';

  const theme = useTheme();

  const simpleFooter = (
    <Box
      component="footer"
      sx={{
        py: 5,
        textAlign: 'center',
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Container>
        <Logo sx={{ mb: 1, mx: 'auto' }} />

        <Typography variant="caption" component="div">
          © All rights reserved
          <br /> made by
          <Link href="https://minimals.cc/"> minimals.cc </Link>
        </Typography>
      </Container>
    </Box>
  );

  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      {/* <Divider /> */}

      <Grid container>
        <Grid item xs={12} md={6} sx={{ backgroundColor: '' }}>
          <StyledLeft>
            <Grid
              container
              justifyContent={{
                xs: 'center',
              }}
              sx={{
                textAlign: {
                  xs: 'center',
                  md: 'left',
                },
                pt: 5,
                // backgroundColor: '#f3f3f3',
                height: '100%',
              }}
            >
              <Grid item>
                {/* <Grid item sx={{ backgroundColor: '#f3f3f3' }}> */}
                <Grid item xs={12} sx={{ mb: 3, px: 2 }}>
                  <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
                </Grid>

                <Stack
                  spacing={{ xs: 5, sm: 20 }}
                  justifyContent="space-between"
                  direction={{ xs: 'column', sm: 'row', md: 'row' }}
                  pt={2}
                  px={2}
                >
                  <Stack spacing={2} alignItems={{ xs: 'center', md: 'flex-start' }}>
                    <Typography component="div" variant="overline">
                      <Link
                        href="tel:+13522734103"
                        target="_blank"
                        color={
                          theme.palette.mode === 'light' ? theme.palette.text.primary : 'white'
                        }
                      >
                        (352) 273-4103
                      </Link>
                    </Typography>
                    <Typography component="div" variant="overline">
                      <Link
                        href="mailto:Lastinger@coe.ufl.edu"
                        target="_blank"
                        color={
                          theme.palette.mode === 'light' ? theme.palette.text.primary : 'white'
                        }
                      >
                        Lastinger@coe.ufl.edu
                      </Link>
                    </Typography>
                  </Stack>

                  <Stack spacing={2} alignItems={{ xs: 'center', md: 'flex-start' }}>
                    <Typography component="div" variant="overline">
                      PO Box 117052
                    </Typography>
                    <Typography component="div" variant="overline">
                      Gainesville, FL 32611
                    </Typography>
                  </Stack>
                </Stack>

                <Stack
                  spacing={1}
                  direction="row"
                  justifyContent={{ xs: 'center', sm: 'flex-start' }}
                  sx={{
                    mt: 1,
                    mb: { xs: 5, md: 0 },
                  }}
                >
                  {_socials.map((social) => (
                    <Link
                      key={social.name}
                      href={social.path}
                      target="_blank"
                      color={theme.palette.mode === 'light' ? theme.palette.text.primary : 'white'}
                    >
                      <IconButton key={social.name}>
                        <Iconify icon={social.icon} />
                      </IconButton>
                    </Link>
                  ))}
                </Stack>
              </Grid>
            </Grid>
          </StyledLeft>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledRight>
            <Stack
              spacing={5}
              justifyContent="space-between"
              direction={{ xs: 'column', sm: 'row', md: 'row' }}
              pt={{ xs: 0, sm: 10 }}
              px={10}
            >
              {LINKS.map((list) => (
                <Stack
                  key={list.headline}
                  spacing={2}
                  alignItems={{ xs: 'center', md: 'flex-start' }}
                >
                  <Typography component="div" variant="overline" color="white">
                    {list.headline}
                  </Typography>

                  {list.children.map((link) => (
                    <Link
                      key={link.name}
                      component={RouterLink}
                      href={link.href}
                      color="white"
                      variant="body2"
                      target="_blank"
                    >
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </StyledRight>
        </Grid>
      </Grid>
    </Box>
  );

  return isHome ? simpleFooter : mainFooter;
}
