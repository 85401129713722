// routes
import { paths } from 'src/routes/paths';
// config
// components
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export const navConfigUnAuthed = [
  {
    title: 'Home',
    icon: <Iconify icon="eva:home-fill" />,
    path: '/',
  },
  // {
  //   title: 'Login',
  //   icon: <Iconify icon="ic:round-grain" />,
  //   path: '#',
  // },
  {
    title: 'Support',
    icon: <Iconify icon="ic:round-grain" />,
    path: paths.support,
  },
];

export const navConfigAuthedAdminApprover = [
  {
    title: 'Users',
    path: paths.users,
    icon: <Iconify icon="fluent:person-24-filled" />,
  },
  {
    title: 'Log History',
    path: paths.loghistory,
    icon: <Iconify icon="fluent:timeline-24-regular" />,
  },
  {
    title: 'Data Sets',
    path: paths.datasets,
    icon: <Iconify icon="fluent:data-pie-20-filled" />,
  },
  {
    title: 'Support',
    icon: <Iconify icon="ic:round-grain" />,
    path: paths.support,
  },
  // {
  //   title: 'Logout',
  //   icon: <Iconify icon="ic:round-grain" />,
  //   path: '#',
  // },
];

export const navConfigAuthed = [
  {
    title: 'Log History',
    path: paths.loghistory,
    icon: <Iconify icon="fluent:timeline-24-regular" />,
  },
  {
    title: 'Data Sets',
    path: paths.datasets,
    icon: <Iconify icon="fluent:data-pie-20-filled" />,
  },
  {
    title: 'Support',
    icon: <Iconify icon="ic:round-grain" />,
    path: paths.support,
  },
];
