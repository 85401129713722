import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
//
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import App from './App';
// DataLagoon
import { DataLagoonProvider } from './DataLagoonContext';
import { msalConfig } from './authConfig';
import Me from './Me';
// ----------------------------------------------------------------------

export const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// msalInstance.addEventCallback((message: EventMessage) => {
//     // Update UI or interact with EventMessage here
//     if (message.eventType === EventType.LOGIN_SUCCESS) {
//         console.log(message.payload);
//         console.log("You've logged in successfully.");

//      }

// });

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <MsalProvider instance={msalInstance}>
          <DataLagoonProvider>
            <Me />
            <App />
          </DataLagoonProvider>
        </MsalProvider>
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
);
