import { createContext, useReducer } from 'react';

export const DataLagoonContext = createContext();

const reducer = (state, pair) => ({ ...state, ...pair });

const initialState = {
  userIcon: null,
  me: null,
};

export function DataLagoonProvider(props) {
  const [state, update] = useReducer(reducer, initialState);

  /* eslint-disable react/prop-types */
  const { children } = props;

  return (
    /* eslint-disable react/prop-types */
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <DataLagoonContext.Provider value={{ state, update }}>{children}</DataLagoonContext.Provider>
  );
}

// DataLagoonProvider.propTypes = {
//   children: PropTypes,
// };
