import { useEffect, useState } from 'react';
// import { useMsal } from '@azure/msal-react';

import { Button, Container, Box, Typography, Stack, Paper, styled } from '@mui/material';

// Azure AD
import { IPublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignIn = () => {
  const { instance } = useMsal();
  const [signinFailed, setSigninFailed] = useState(false);

  // };
  async function handleLogin(instancePassed: IPublicClientApplication) {
    try {
      instancePassed.loginPopup(loginRequest).catch((e) => {
        setSigninFailed(true);
      });
    } catch (ee) {
      setSigninFailed(true);
    }
  }

  const Item = styled(Paper)(({ theme }: any) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  useEffect(() => {
    handleLogin(instance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {signinFailed ? (
        <Container>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
            sx={{ marginTop: '-100px' }}
          >
            <Stack>
              <Item>
                <Typography>Looks like you&apos;re not signed-in</Typography>
              </Item>
              <Item>
                <Button variant="contained" onClick={() => handleLogin(instance)}>
                  Sign-in
                </Button>
              </Item>
            </Stack>
          </Box>
        </Container>
      ) : (
        <Container>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
            sx={{ marginTop: '-100px' }}
          >
            <Stack>
              <Item>
                <Typography>Signing you in</Typography>
              </Item>
            </Stack>
          </Box>
        </Container>
      )}
    </div>
  );
};
