import { forwardRef } from 'react';
// @mui
import Link from '@mui/material/Link';
import Box, { BoxProps } from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';
import { useSettingsContext } from '../settings/context';

// import { ReactComponent as LogoSvg } from 'src/assets/UF-Lastinger-Center.svg';
// import UFLogo from './uf-logo';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const settings = useSettingsContext();

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          maxWidth: 350,
          maxHeight: 48,
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
      >
        {settings.themeMode === 'light' ? (
          <img src="/assets/Lastinger-Center-Logos_2021-01-980x103.png" alt="logo" />
        ) : (
          <img src="/assets/Lastinger-white.png" alt="logo" />
        )}
      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
